/**
 * NAVIGATION
 */

const navigation = () => {
    const header = document.querySelector('.js-header');
    const hamburger = document.querySelector('.js-header-hamburger');
  
    if (hamburger) {
      hamburger.addEventListener('click', () => {
        if (header) {
          header.classList.toggle('active');
          document.body.classList.toggle('overflow');
        }
      });
    }
};

export default navigation;
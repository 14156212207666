import test from './components/test';
import navigation from './components/navigation';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test();
    navigation();
  },
  false
);
